// styling
import "./style.scss";

// libs styles
import "swiper/css";
import "swiper/css/effect-fade";
import "react-toastify/dist/ReactToastify.css";

// utils
import { lazy, Suspense } from "react";
import { preventDefault } from "@utils/helpers";

// hooks
import { useEffect } from "react";

// context
import { SidebarContextAPI } from "@contexts/sidebarContext";

// components
import LoadingScreen from "@components/LoadingScreen";
import AppLayout from "@components/AppLayout";
import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "@components/ScrollToTop";

// pages
const Home = lazy(() => import("@pages/Home"));
const PrivacyPolicy = lazy(() => import("@pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("@pages/TermsAndConditions"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));

const App = () => {
  useEffect(() => {
    preventDefault();
  }, []);

  // Matomo analytics
  useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src =
      "https://cdn.matomo.cloud/tangiblink.matomo.cloud/container_Bl0pXKId.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <SidebarContextAPI>
      <ScrollToTop />
      <AppLayout>
        <Suspense fallback={<LoadingScreen visible />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Suspense>
      </AppLayout>
    </SidebarContextAPI>
  );
};

export default App;
